import React from 'react';
import { Nav, Navbar } from 'react-bootstrap';
import { Link } from 'react-router-dom';

import useToken from '../../common/useToken';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';

import 'bootstrap/dist/css/bootstrap.min.css';
import '../../styles/Header.css';
import { CalendarMonth } from '@mui/icons-material';

export default function Header() {
	const { setToken } = useToken();

	return (
		<>
			<Navbar
				collapseOnSelect
				expand='sm'
				className='bg-corp navbar-fixed-top justify-content-start'
				variant='dark'
			>
				<Navbar.Toggle
					aria-controls='navbarScroll'
					data-bs-toggle='collapse'
					data-bs-target='#navbarScroll'
				/>
				<Link to='/'>
					<div className='d-flex align-items-center'>
						<img
							alt='logo'
							style={{ width: '200px', marginLeft: '10px' }}
							src='/img/logo_white.png'
						></img>
						{/* <h5 className="m-0" style={{color:"white", width:"235px"}}>Crossmedia</h5> */}
					</div>
				</Link>
				<Navbar.Collapse id='navbarScroll'>
					<Nav
						className='nav_menu_header'
						style={{ alignItems: 'center', verticalAlign: 'middle' }}
					>
						<div className='d-flex align-items-center'>
							{/* <NavLink className="logo" eventKey="1" as={Link} to="/"><img alt="logo" style={{width:"32px"}} src="/img/favicon.png"></img></NavLink> */}
						</div>

						<div className='d-flex align-items-center justify-content-center'>
							<Link to='/calendar' className='btn btn-logout me-2'>
								<a
									eventKey='calendar'
									src={Link}
									to='/calendar'
									onClick={() => {
										// ir a /calendar con react router dom
									}}
								>
									<CalendarMonth className='me-2' fontSize='small' />{' '}
									Calendario
								</a>
							</Link>

							<a
								className='btn btn-logout me-2'
								eventKey='1'
								src={Link}
								to='/'
								onClick={() => {
									setToken(null);
									window.localStorage.removeItem('loggedUserToken');
									window.localStorage.removeItem('loggedUserData');
								}}
							>
								<ExitToAppIcon className='me-2' fontSize='small' /> Cerrar
								Sesión
							</a>
						</div>
					</Nav>
				</Navbar.Collapse>
			</Navbar>
		</>
	);
}
