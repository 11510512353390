import React from 'react'
import { Routes, Route, BrowserRouter as Router} from 'react-router-dom';
import { Calendario } from '../calendar/Calendario';

import Header from '../common/Header';

import Home from '../home/Home';

export default function Main() {
    return (
        <div className="App scale-in">
            <Router>
                <Header />
                <Routes>
                    <Route index path="/" element={<Home />} />
                    <Route path="/calendar" element={<Calendario />} />
                </Routes>
            </Router>
        </div>
    );
}
