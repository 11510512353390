import React from "react";

const GetHour = () => {
    const [hour, setHour] = React.useState("");

    const getHour = () => {
        var hour = new Date(new Date()).getHours();
        return isNaN(hour) ? null : (hour < 10 ? '0' : '') + hour;
    }

    const getMinutes = () => {
        var minutes = new Date(new Date()).getMinutes();
        return isNaN(minutes) ? null : minutes < 10 ? '0' + minutes : minutes;
    }

    React.useEffect(() => {
        let hour = getHour() + ':' + getMinutes();
        setHour(hour);

        setInterval(() => {
            hour = getHour() + ':' + getMinutes();
            setHour(hour)
        }, 15000);
    }, []);

    return(
        hour
    )
}
export default GetHour;