import React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { Input } from '@mui/material';
import { statusPassword } from './showChangeModal';
import useData from '../../common/useData';

const style = {
	position: 'absolute',
	top: '50%',
	left: '50%',
	transform: 'translate(-50%, -50%)',
	width: 400,
	bgcolor: 'background.paper',
	boxShadow: 24,
	p: 4
};

export default function ChangePassword({ type }) {
	const { openStatus, setOpenStatus } = statusPassword();
	const { data } = useData();

	const [error, setError] = React.useState(0);
	const [password, setPassword] = React.useState('');
	const [password2, setPassword2] = React.useState('');

	return (
		<div>
			<Modal
				open={openStatus}
				aria-labelledby='modal-modal-title'
				aria-describedby='modal-modal-description'
			>
				<Box sx={style}>
					<Typography id='modal-modal-title' variant='h6' component='h2'>
						Actualizar contraseña
					</Typography>

					{type == 'firstLoggin' ? (
						<p>
							Por motivos de seguridad es necesario que cambies tu
							contraseña
						</p>
					) : null}
					<Input
						type='password'
						placeholder='Nueva contraseña'
						className='w-100 mb-2'
						onChange={(e) => setPassword(e.target.value)}
					/>
					<Input
						type='password'
						placeholder='Repite la contraseña'
						className='w-100'
						onChange={(e) => setPassword2(e.target.value)}
					/>
					<div
						className={
							error == 1
								? 'text-danger animate__animated animate__fadeIn'
								: 'text-danger animate__animated animate__fadeIn d-none'
						}
					>
						La longitud mínima de la contraseña es de 8 carácteres
					</div>
					<div
						className={
							error == 2
								? 'text-danger animate__animated animate__fadeIn'
								: 'text-danger animate__animated animate__fadeIn d-none'
						}
					>
						Las contraseñas no coinciden
					</div>
					<Button
						className='float-end mt-2'
						onClick={() => {
							if (
								password == password2 &&
								password != '' &&
								password.length >= 8
							) {
								fetch(
									'https://server.fichajes.crossmedia360.com/changePassword',
									{
										method: 'POST',
										headers: { 'Content-Type': 'application/json' },
										body: JSON.stringify({
											dni: data.dni,
											password: password
										})
									}
								)
									.then((res) => res.json())
									.then((response) => {
										if (response.updated) {
											setOpenStatus(false);
										}
									});
							} else {
								if (password != password2) {
									setError(2);
								} else {
									setError(1);
								}
							}
						}}
					>
						Actualizar
					</Button>
				</Box>
			</Modal>
		</div>
	);
}
