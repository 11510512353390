import React, { useEffect, useState } from 'react';

import CoffeeIcon from '@mui/icons-material/Coffee';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import AlarmOnIcon from '@mui/icons-material/AlarmOn';
import MonitorHeartIcon from '@mui/icons-material/MonitorHeart';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import MoreTimeIcon from '@mui/icons-material/MoreTime';
import CheckIcon from '@mui/icons-material/Check';

import { Calendar } from 'primereact/calendar';
import { addLocale } from 'primereact/api';

import 'primeicons/primeicons.css';
import 'primereact/resources/themes/lara-light-indigo/theme.css';
import 'primereact/resources/primereact.css';

import useData from '../../common/useData';

import { EnhancedTable } from './Table';
import Contador from './Contador';
import ContadorDescanso from './ContadorDescanso';
import GetHour from './GetHour';
import ChangePassword from '../login/ChangePassword';

import { NotificationContainer, NotificationManager } from 'react-notifications';
import 'react-notifications/lib/notifications.css';

export default function Home() {
	const [fichaje, setFichaje] = useState(false);
	const [fichajesData, setFichajesData] = useState([]);
	const [anuncios, setAnuncios] = useState([]);

	const [employee, setEmployee] = useState({});
	const [sedeInfo, setSedeInfo] = useState({});
	const [diasVacaciones, setDiasVacaciones] = useState(0);

	const [entrada, setEntrada] = useState('');
	const [descanso, setDescanso] = useState(false);
	const [observaciones, setObservaciones] = useState(null);
	const [type, setType] = useState('normal');
	const [compDate, setCompDate] = useState(new Date());

	const [typeHour, setTypeHour] = useState('');
	const [refreshPage, setRefreshPage] = useState(false);

	const [refresh, setRefresh] = useState(false);
	const { data } = useData();

	const [dateHolidays, setDateHolidays] = useState(null);
	const [requestType, setRequestType] = useState('');
	const [requestReason, setRequestReason] = useState('');

	const [editFichaje, setEditFichaje] = React.useState(false);
	const [selectedFichaje, setSelectedFichaje] = React.useState({});

	const [buttonBlocked, setButtonBlocked] = useState(false);

	const [ficharEntradaButton, setFicharEntrada] = useState(false);
	const [ficharSalidaButton, setFicharSalida] = useState(false);
	const [yearRequest, setYearRequest] = useState(new Date().getFullYear());

	const [horario, setHorario] = useState({});
	const [vacaciones, setVacaciones] = useState([]);
	const [festivos, setFestivos] = useState([]);

	function getDayOfWeek() {
		var dayOfWeek = new Date(new Date()).getDay();
		return isNaN(dayOfWeek)
			? null
			: ['Domingo', 'Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sábado'][
					dayOfWeek
			  ];
	}

	function getMonth() {
		var month = new Date(new Date()).getMonth();
		return isNaN(month)
			? null
			: [
					'Enero',
					'Febrero',
					'Marzo',
					'Abril',
					'Mayo',
					'Junio',
					'Julio',
					'Agosto',
					'Septiembre',
					'Octubre',
					'Noviembre',
					'Diciembre'
			  ][month];
	}

	function getDay() {
		var day = new Date(new Date()).getDate();
		return isNaN(day) ? null : day;
	}

	function ficharEntrada() {
		fetch('https://server.fichajes.crossmedia360.com/ficharEntrada', {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json'
			},
			body: JSON.stringify({
				dni: data.dni,
				observaciones_entrada: observaciones,
				tipo: type,
				compDate: compDate,
				horario: horario
			})
		})
			.then((res) => res.json())
			.then((response) => {
				setFichaje(true);
				setObservaciones('');
				setRefresh(!refresh);
			});
	}

	function ficharSalida() {
		fetch('https://server.fichajes.crossmedia360.com/ficharSalida', {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json'
			},
			body: JSON.stringify({ dni: data.dni, observaciones_salida: observaciones })
		})
			.then((res) => res.json())
			.then((response) => {
				setEntrada('');
				setFichaje(false);
				setObservaciones('');
				setType('normal');
				setRefresh(!refresh);
			});
	}

	function ficharDescanso() {
		fetch('https://server.fichajes.crossmedia360.com/ficharDescanso', {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json'
			},
			body: JSON.stringify({ dni: data.dni, observaciones_descanso: observaciones })
		})
			.then((res) => res.json())
			.then((response) => {
				setObservaciones('');
				setDescanso(true);
			});
	}

	function ficharVueltaDescanso() {
		fetch('https://server.fichajes.crossmedia360.com/ficharVueltaDescanso', {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json'
			},
			body: JSON.stringify({
				dni: data.dni,
				observaciones_vueltaDescanso: observaciones
			})
		})
			.then((res) => res.json())
			.then((response) => {
				setObservaciones('');
				setDescanso(false);
			});
	}

	function ficharVuelta() {
		fetch('https://server.fichajes.crossmedia360.com/ficharVuelta', {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json'
			},
			body: JSON.stringify({
				dni: data.dni,
				observaciones_vuelta: observaciones,
				tipo: type
			})
		})
			.then((res) => res.json())
			.then((response) => {
				setType('normal');
				setObservaciones('');
			});
	}

	const sendRequest = () => {
		setButtonBlocked(true);
		fetch('https://server.fichajes.crossmedia360.com/sendRequest', {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json'
			},
			body: JSON.stringify({
				dni: data.dni,
				requestType: requestType || 'diasPropios',
				dateHolidays: dateHolidays,
				requestReason: requestReason,
				year: yearRequest
			})
		})
			.then((res) => res.json())
			.then((response) => {
				if (response.inserted) {
					NotificationManager.success(
						'Solicitud enviada correctamente',
						'Enviada',
						3000
					);
				} else if (!response.dias) {
					NotificationManager.error(
						'No tienes días suficientes, te quedan: ' +
							response.diasRestantes,
						'Error',
						3000
					);
				} else {
					NotificationManager.error(
						'Error al enviar la solicitud',
						'Error',
						3000
					);
				}

				setRequestType('');
				setDateHolidays('');
				document.getElementById('vacaciones').checked = false;
				document.getElementById('diasPropios').checked = false;
				document.getElementById('otrosMotivos').checked = false;

				setRequestReason('');
				setButtonBlocked(false);
			});
	};

	const handleEdit = () => {
		fetch('https://server.fichajes.crossmedia360.com/editFichaje', {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json'
			},
			body: JSON.stringify({
				fichaje: selectedFichaje,
				dni: data.dni
			})
		})
			.then((res) => res.json())
			.then((response) => {
				setEditFichaje(false);
				setRefresh(!refresh);
			});
	};

	function enFechaDiasPropiosLastYear() {
		const fechaActual = new Date();

		if (fechaActual.getMonth() == 0) {
			return true;
		} else if (fechaActual.getMonth() == 1 && fechaActual.getDate() <= 15)
			return true;
		else return false;
	}

	function getAdjustedDate(dateString) {
		// Obtener la fecha y hora actual
		const currentDate = new Date();

		// Obtener la diferencia horaria de la zona actual en minutos
		const timeZoneOffsetMinutes = currentDate.getTimezoneOffset();

		// Convertir la diferencia horaria en milisegundos y agregar la cantidad de horas adecuada
		const timeZoneOffsetMs = timeZoneOffsetMinutes * 60 * 1000;
		const date = new Date(dateString);
		return new Date(date.getTime() - timeZoneOffsetMs).toISOString().slice(0, -8);
	}

	function convertirSegundos(segundos) {
		const horas = Math.floor(segundos / 3600);
		segundos %= 3600;
		const minutos = Math.floor(segundos / 60);
		segundos %= 60;

		return horas + ' horas ' + minutos + ' minutos ' + segundos + ' segundos';
	}

	function calcularHoras(entrada, salida) {
		if (entrada === 'none' || salida === 'none') {
			return 0;
		}

		var entradaHora = new Date('1970-01-01T' + entrada + ':00');
		var salidaHora = new Date('1970-01-01T' + salida + ':00');

		var diferencia = salidaHora - entradaHora;
		var horas = diferencia / (1000 * 60 * 60);

		return horas;
	}

	// Calcular el total de horas por día
	function calcularTotalHorasPorDia(dia) {
		if (!employee || !employee.horario) return 0;

		const horarioObj = JSON.parse(employee.horario);

		var totalHoras = 0;

		for (var turno = 1; turno <= 2; turno++) {
			var entrada = horarioObj['entrada' + dia + '_' + turno];
			var salida = horarioObj['salida' + dia + '_' + turno];

			totalHoras += calcularHoras(entrada, salida);
		}

		return totalHoras;
	}

	function obtenerUltimoDiaMes() {
		var fechaActual = new Date();
		var ultimoDiaMes = new Date(
			fechaActual.getFullYear(),
			fechaActual.getMonth() + 1,
			0
		).getDate();
		return ultimoDiaMes;
	}

	// Función para calcular el total de horas en el mes actual
	function calcularTotalHorasMes() {
		var fechaActual = new Date();
		var ultimoDiaMes = obtenerUltimoDiaMes();
		var totalHorasMes = 0;

		// Iterar sobre los días laborables del mes
		for (var dia = 1; dia <= ultimoDiaMes; dia++) {
			var diaSemana = new Date(
				fechaActual.getFullYear(),
				fechaActual.getMonth(),
				dia
			).getDay();

			// Si el día es laborable (lunes a viernes)
			if (diaSemana >= 1 && diaSemana <= 5) {
				var totalHorasDia = calcularTotalHorasPorDia(diaSemana);
				totalHorasMes += totalHorasDia;
			}
		}

		return totalHorasMes;
	}

	function calcularTotalHorasHastaHoy() {
		var fechaActual = new Date();
		var ultimoDiaMes = fechaActual.getDate();
		var totalHorasMes = 0;

		// Iterar sobre los días laborables hasta la fecha actual
		for (var dia = 1; dia <= ultimoDiaMes; dia++) {
			var diaSemana = new Date(
				fechaActual.getFullYear(),
				fechaActual.getMonth(),
				dia
			).getDay();

			// Si el día es laborable (lunes a viernes) y no es después de hoy
			if (
				diaSemana >= 1 &&
				diaSemana <= 5 &&
				new Date(fechaActual.getFullYear(), fechaActual.getMonth(), dia) <=
					fechaActual
			) {
				var totalHorasDia = calcularTotalHorasPorDia(diaSemana);
				totalHorasMes += totalHorasDia;
			}
		}

		return totalHorasMes;
	}

	const getHorasMensuales = () => {
		return {
			horas: calcularTiempoRestante(
				sedeInfo.horasMensuales,
				employee.horasMensuales,
				diasVacaciones * 7
			).horas,
			minutos: calcularTiempoRestante(
				sedeInfo.horasMensuales,
				employee.horasMensuales,
				diasVacaciones * 7
			).minutos,
			segundos: calcularTiempoRestante(
				sedeInfo.horasMensuales,
				employee.horasMensuales,
				diasVacaciones * 7
			).segundos,
			horasVacaciones: diasVacaciones * 7
		};
	};

	function calcularTotalHorasEntreEventos(eventos) {
		var totalHoras = 0;

		for (const evento of eventos) {
			if (evento.type == 2) continue;
			if (new Date(evento.fechaInicio) > new Date()) continue;

			var fechaInicio = new Date(evento.fechaInicio || evento.startDate);
			var fechaFin = new Date(evento.fechaFin || evento.endDate);

			// Iterar sobre los días entre fechaInicio y fechaFin
			while (fechaInicio <= fechaFin) {
				var diaSemana = fechaInicio.getDay(); // 0: Domingo, 1: Lunes, ..., 6: Sábado

				// Si el día es laborable (lunes a viernes)
				if (diaSemana >= 1 && diaSemana <= 5) {
					var totalHorasDia = calcularTotalHorasPorDia(diaSemana);

					totalHoras += totalHorasDia;
				}

				// Mover a la siguiente fecha
				fechaInicio.setDate(fechaInicio.getDate() + 1);
			}
		}

		return totalHoras;
	}

	// const Mensajes = () => {
	//     return (
	//         <div>
	//             <p>Tiene que hacer {sedeInfo.horasMensuales} horas mensuales en segundos: {sedeInfo.horasMensuales * 3600} seg</p>
	//             <p>{employee.horasMensuales} segundos tengo este mes</p>
	//             <p>{employee.horasMensuales < sedeInfo.horasMensuales * 3600 ? (sedeInfo.horasMensuales * 3600 - employee.horasMensuales) + " segundos faltan por hacer" : (employee.horasMensuales - sedeInfo.horasMensuales * 3600) + " segundos a más"}</p>
	//             {/* quiero las horas que deberia llevar este mes */}
	//             <p>{diasVacaciones} dias de vac, {diasVacaciones * 7} horas de vac, {(diasVacaciones * 7)*3600} seg este mes</p>

	//             <p>{(sedeInfo.horasMensuales * 3600) / 30 * new Date().getDate()} segundos deberías tener este mes sin contar vacas</p>

	//             <h1>
	//                 D:
	//                 {calcularTiempoRestante(sedeInfo.horasMensuales, employee.horasMensuales, diasVacaciones * 7).horas} horas
	//                 {calcularTiempoRestante(sedeInfo.horasMensuales, employee.horasMensuales, diasVacaciones * 7).minutos} minutos
	//                 {calcularTiempoRestante(sedeInfo.horasMensuales, employee.horasMensuales, diasVacaciones * 7).segundos} segundos
	//                 {diasVacaciones * 7} horas de vacaciones
	//             </h1>

	//             <p>{((sedeInfo.horasMensuales * 3600) / 31 * new Date().getDate()) - ((diasVacaciones * 7)*3600)} segundos deberías tener este mes contando las vacaciones</p>
	//             <p>{Math.abs((((sedeInfo.horasMensuales * 3600) / 30 * new Date().getDate()) - ((diasVacaciones * 7)*3600)))}

	//                 {(((sedeInfo.horasMensuales * 3600) / 30 * new Date().getDate()) - ((diasVacaciones * 7)*3600))
	//                     < 0
	//                     ? "segundos a mayores"
	//                     : "segundos faltan"
	//                 }
	//             </p>

	//             <p>---</p>
	//             <p>Tiene que hacer {sedeInfo.horasAnuales} horas Anuales en segundos: {sedeInfo.horasAnuales * 3600} seg</p>
	//             <p>{employee.totalHoras} segundos tengo este año</p>
	//             <p>{employee.totalHoras < sedeInfo.horasAnuales * 3600 ? (sedeInfo.horasAnuales * 3600 - employee.totalHoras) + " segundos faltan por hacer" : (employee.totalHoras - sedeInfo.horasAnuales * 3600) + " segundos a más"}</p>
	//             <p></p>
	//         </div>
	//     )
	// }

	function calcularTiempoRestante(totalHoras, segundosHechos, horasVacaciones) {
		// Convertir las horas de vacaciones a segundos
		const segundosVacaciones = horasVacaciones * 3600;

		// Sumar las horas de vacaciones a los segundos hechos
		const segundosHechosConVacaciones = segundosHechos + segundosVacaciones;

		// Convertir el total de horas a segundos
		const segundosTotales = totalHoras * 3600;

		// Calcular los segundos restantes
		const segundosRestantes = segundosTotales - segundosHechosConVacaciones;

		// Calcular las horas restantes
		const horasRestantes = Math.floor(segundosRestantes / 3600);

		// Calcular los minutos restantes
		const minutosRestantes = Math.floor((segundosRestantes % 3600) / 60);

		// Calcular los segundos restantes
		const segundosRestantesFinal = segundosRestantes % 60;

		return {
			horas: horasRestantes,
			minutos: minutosRestantes,
			segundos: segundosRestantesFinal
		};
	}

	addLocale('es', {
		firstDayOfWeek: 1,
		dayNames: [
			'domingo',
			'lunes',
			'martes',
			'miércoles',
			'jueves',
			'viernes',
			'sábado'
		],
		dayNamesShort: ['dom', 'lun', 'mar', 'mié', 'jue', 'vie', 'sáb'],
		dayNamesMin: ['D', 'L', 'M', 'X', 'J', 'V', 'S'],
		monthNames: [
			'enero',
			'febrero',
			'marzo',
			'abril',
			'mayo',
			'junio',
			'julio',
			'agosto',
			'septiembre',
			'octubre',
			'noviembre',
			'diciembre'
		],
		monthNamesShort: [
			'ene',
			'feb',
			'mar',
			'abr',
			'may',
			'jun',
			'jul',
			'ago',
			'sep',
			'oct',
			'nov',
			'dic'
		],
		today: 'Hoy',
		clear: 'Limpiar'
	});

	useEffect(() => {
		fetch('https://server.fichajes.crossmedia360.com/getEmployee', {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json'
			},
			body: JSON.stringify({ dni: data.dni })
		})
			.then((res) => res.json())
			.then((response) => {
				setEmployee(response.employee);
				setSedeInfo(response.sedeInfo);
				setFestivos(response.festivos);
				setVacaciones(response.vacaciones);

				let totalHoras = 0;
				response.vacaciones?.forEach((vacacion) => {
					totalHoras += vacacion.diasVacaciones;
				});

				setDiasVacaciones(totalHoras);
			});

		fetch('https://server.fichajes.crossmedia360.com/getAllFichajesByEmployee', {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json'
			},
			body: JSON.stringify({ dni: data.dni })
		})
			.then((res) => res.json())
			.then((response) => {
				setFichajesData(response.fichajes);
			});

		fetch('https://server.fichajes.crossmedia360.com/getStatusFichaje', {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json'
			},
			body: JSON.stringify({ dni: data.dni })
		})
			.then((res) => res.json())
			.then((response) => {
				if (response.status === 'Fichaje activo') {
					setEntrada(response.entrada);
					setFichaje(true);
				} else if (response.status === 'Especial') {
					setRefreshPage(true);
					setEntrada(response.entrada);
					setTypeHour(response.especial);
					setFichaje(true);
					setType(response.tipo);
				} else if (response.status === 'Fichaje inactivo') {
					setFichaje(false);
				} else if (response.status === 'Descanso') {
					setEntrada(response.entrada);
					setFichaje(true);
					setDescanso(response.descanso);
				}
			});

		fetch('https://server.fichajes.crossmedia360.com/getAnuncios', {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json'
			},
			body: JSON.stringify({ sede: data.sede, ccaa: data.ccaa })
		})
			.then((res) => res.json())
			.then((response) => {
				setAnuncios(response.anuncios);
			});
	}, [refresh]);

	useEffect(() => {
		if (fichaje && type != 'normal') {
			if (!refreshPage) {
				fetch('https://server.fichajes.crossmedia360.com/ficharEspecial', {
					method: 'POST',
					headers: {
						'Content-Type': 'application/json'
					},
					body: JSON.stringify({ dni: data.dni, tipo: type })
				})
					.then((res) => res.json())
					.then((response) => {
						setRefresh(!refresh);
					});
			}
		}
		setRefreshPage(false);
	}, [type]);

	useEffect(() => {
		fetch('https://server.fichajes.crossmedia360.com/getHorario', {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json'
			},
			body: JSON.stringify({ dni: data.dni })
		})
			.then((res) => res.json())
			.then((response) => {
				console.log(response);
				if (!response.error) {
					setHorario(response.horario);
				}
			})
			.catch((err) => {
				console.log(err);
			});
	}, []);

	return (
		<>
			<div className='w-100 h-100 bg-grey scroll-y'>
				<div
					className='container-fluid fade-in mt-5'
					style={{ paddingBottom: '7vh' }}
				>
					<div className='row m-2 mt-2'>
						<div className='col-xl-4 col-lg-6 col-md-12 mb-4 order-2 order-sm-2 order-lg-1'>
							<div className='card'>
								<div className='card-body'>
									<h4 className='text-center color-grey fw-lighter text-uppercase'>
										HISTORIAL
									</h4>
									<div className='row mt-4'>
										<div className='col-12'>
											<EnhancedTable
												data={fichajesData}
												setSelectedFichaje={setSelectedFichaje}
												setEditFichaje={setEditFichaje}
											/>
										</div>
									</div>
								</div>
							</div>

							{editFichaje ? (
								<div className='card mt-3'>
									<div className='card-body'>
										<h4 className='text-center color-grey fw-lighter text-uppercase'>
											EDITAR FICHAJE
										</h4>
										<div className='row mt-4'>
											<div className='col-12'>
												<label
													htmlFor='iEntrada'
													className='form-label'
												>
													Entrada
												</label>
												<input
													type='datetime-local'
													className='form-control'
													id='iEntrada'
													defaultValue={getAdjustedDate(
														selectedFichaje.entrada
													)}
													onChange={(e) =>
														setSelectedFichaje({
															...selectedFichaje,
															entrada: e.target.value
														})
													}
												/>

												<label
													htmlFor='iSalida'
													className='form-label mt-4'
												>
													Salida
												</label>
												<input
													type='datetime-local'
													className='form-control'
													id='iSalida'
													defaultValue={
														selectedFichaje.salida &&
														selectedFichaje.salida !==
															'En curso'
															? getAdjustedDate(
																	selectedFichaje.salida
															  )
															: null
													}
													onChange={(e) =>
														setSelectedFichaje({
															...selectedFichaje,
															salida: e.target.value
														})
													}
												/>

												<button
													className='btn btn-primary mt-4 me-2'
													onClick={() => setEditFichaje(false)}
												>
													Cancelar
												</button>
												<button
													className='btn btn-warning mt-4 me-2'
													onClick={() => {
														fetch(
															'https://server.fichajes.crossmedia360.com/compensarFichaje',
															{
																method: 'POST',
																headers: {
																	'Content-Type':
																		'application/json'
																},
																body: JSON.stringify({
																	dni: data.dni,
																	id: selectedFichaje
																		.item.id,
																	entrada:
																		selectedFichaje.entrada,
																	salida: selectedFichaje.salida,
																	horario: horario
																})
															}
														)
															.then((res) => res.json())
															.then((response) => {
																if (response.compensado) {
																	setRefresh(!refresh);
																	setEditFichaje(false);

																	NotificationManager.success(
																		'Fichaje compensado correctamente',
																		'Compensado',
																		3000
																	);
																} else {
																	NotificationManager.error(
																		'Error al compensar el fichaje',
																		'Error',
																		3000
																	);
																}
															})
															.catch((error) => {
																console.log(error);
															});
													}}
												>
													Compensar Fichaje
												</button>
												<button
													className='btn btn-fichar mt-4'
													onClick={handleEdit}
												>
													Editar
												</button>
											</div>
										</div>
									</div>
								</div>
							) : null}

							{/* <div className='card col-xl-4 col-lg-6 col-md-12 mb-4 order-2 order-sm-2 order-lg-1 w-100 mt-4'>
								<div className='card-body'>
									<h4 className='text-center color-grey fw-lighter text-uppercase'>
										HORAS REALIZADAS
									</h4>
									<div className='row mt-2'>
										<div className='col-sm-6 mt-3'>
											<div className='info p-2 h-100'>
												<label>Horas Mensuales</label>
												<div>
													{convertirSegundos(
														employee.horasMensuales
													)}
													
												</div>
											</div>
										</div>
										<div className='col-sm-6 mt-3'>
											<div className='info p-2 h-100'>
												<label>Horas Anuales</label>
												<div>
													{convertirSegundos(
														employee.totalHoras
													)}
												</div>
											</div>
										</div>

										{employee.ccaa == 'Federal' ? (
											<div className='col-sm-12 mt-3'>
												<div className='info p-2 h-100 text-center'>
													<label>Control de Horas</label>

													<div>
														Llevas{' '}
														{convertirSegundos(
															employee.horasMensuales
														)}{' '}
														de <br />
														{convertirSegundos(
															calcularTotalHorasHastaHoy() *
																3600 -
																calcularTotalHorasEntreEventos(
																	festivos
																) *
																	3600 -
																calcularTotalHorasEntreEventos(
																	vacaciones
																) *
																	3600
														)}{' '}
														horas este mes.
													</div>
												</div>
											</div>
										) : null}

									</div>
								</div>
							</div> */}
						</div>
						<div className='col-xl-4 col-lg-6 col-md-12 mb-4 order-1 order-sm-1 order-lg-2'>
							<div className='card'>
								<div className='card-body'>
									<h4 className='color-grey text-center fw-lighter text-uppercase'>
										Fichajes
									</h4>
									<div className='align-items-center d-flex flex-column justify-content-center h-100'>
										{fichaje ? (
											<>
												<div className='time animate__animated animate__fadeInUp'>
													<Contador
														fichaje={fichaje}
														entrada={entrada}
													/>
												</div>
												{descanso ? (
													<div className='time2 animate__animated animate__fadeInUp bg-warning'>
														<CoffeeIcon
															className='me-2'
															fontSize='small'
														/>
														<ContadorDescanso
															descanso={descanso}
														/>
													</div>
												) : type != 'normal' ? (
													<div className='time2 animate__animated animate__fadeInUp bg-warning'>
														{type == 'medico' ? (
															<MonitorHeartIcon
																className='me-2'
																fontSize='small'
															/>
														) : (
															<MoreTimeIcon
																className='me-2'
																fontSize='small'
															/>
														)}
														<ContadorDescanso
															descanso={typeHour}
														/>
													</div>
												) : (
													''
												)}
											</>
										) : (
											''
										)}
										<div className='hour'>
											<div className='hour-number'>
												<h1 className='display-1'>
													{<GetHour />}
												</h1>
											</div>
										</div>
										<div className='date'>
											<div className='day'>
												<h2 className='color-grey text-center'>
													{getDayOfWeek()}, {getDay()} de{' '}
													{getMonth()}
												</h2>
											</div>
										</div>
										<div className='location'>
											<div className='location-number'>
												<h4 className='fw-light color-grey'>
													{data.sede}, {data.ccaa}
												</h4>
											</div>
										</div>
										<div className='text-start w-100'>
											<div className='text-center'>
												<input
													type='checkbox'
													name='medico'
													id='medico'
													checked={type == 'medico'}
													onChange={(e) => {
														if (e.target.checked) {
															setType('medico');
															setTypeHour(new Date());
														} else setType('normal');
													}}
													disabled={
														type == 'compensacion' ||
														type == 'ausenciaJustificada' ||
														(type == 'medico' && fichaje)
													}
												/>{' '}
												<label htmlFor='medico'>Médico</label>
												<input
													type='checkbox'
													className='ms-3'
													name='ausenciaJustificada'
													id='ausenciaJustificada'
													checked={
														type == 'ausenciaJustificada'
													}
													onChange={(e) => {
														if (e.target.checked) {
															setType(
																'ausenciaJustificada'
															);
															setTypeHour(new Date());
														} else setType('normal');
													}}
													disabled={
														type == 'compensacion' ||
														type == 'medico' ||
														(type == 'ausenciaJustificada' &&
															fichaje)
													}
												/>{' '}
												<label htmlFor='ausenciaJustificada'>
													Ausencia Justificada
												</label>
												{/* {!fichaje ? <><input className='ms-3' type={"checkbox"} checked={type == "compensacion"} name="compensacion" id='compensacion' disabled={type == "medico" || type == "ausenciaJustificada" || type == "compensacion" && fichaje} onChange={e => {e.target.checked ? setType("compensacion") : setType("normal")}} /> <label htmlFor="compensacion">Compensación</label></> : null} */}
												{/* {type == "compensacion" && !fichaje ? <><p className='mt-3'>Fecha de Compensación:</p><input type={"date"} className="mb-3 w-50 text-center form-control" style={{display:"unset"}} defaultValue={new Date().getFullYear() + "-" + (new Date().getMonth() + 1) + "-" + new Date().getDate()} onChange={e => {setCompDate(new Date(e.target.value))}} /></> : null} */}
											</div>
											<textarea
												placeholder='Introduce una observación'
												className='form-control'
												value={observaciones}
												id='observations'
												rows='1'
												onChange={(e) =>
													setObservaciones(e.target.value)
												}
											></textarea>
										</div>
									</div>
								</div>
								{fichaje ? (
									<div className='d-flex fichaje'>
										{descanso || type != 'normal' ? (
											<button
												className='btn btn-success w-50 d-flex align-items-center justify-content-center'
												onClick={() => {
													descanso
														? ficharVueltaDescanso()
														: ficharVuelta(type);
												}}
											>
												<CheckIcon
													className='me-2'
													fontSize='small'
												/>{' '}
												VOLVER AL TRABAJO
											</button>
										) : (
											<button
												className='btn btn-warning w-50 d-flex align-items-center justify-content-center'
												onClick={() => {
													ficharDescanso();
												}}
											>
												<CoffeeIcon
													className='me-2'
													fontSize='small'
												/>{' '}
												DESCANSO
											</button>
										)}
										<button
											className='btn btn-primary w-50 d-flex align-items-center justify-content-center'
											disabled={ficharSalidaButton}
											onClick={() => {
												setFicharSalida(true);
												setFicharEntrada(false);
												ficharSalida();
												setType('normal');
											}}
										>
											<ExitToAppIcon
												className='me-2'
												fontSize='small'
											/>{' '}
											FICHAR SALIDA
										</button>
									</div>
								) : (
									<button
										className='btn btn-fichar w-100 d-flex align-items-center justify-content-center'
										disabled={ficharEntradaButton}
										onClick={() => {
											setFicharEntrada(true);
											setFicharSalida(false);
											ficharEntrada();
										}}
									>
										<AlarmOnIcon className='me-2' fontSize='small' />{' '}
										FICHAR ENTRADA
									</button>
								)}
							</div>
							<div className='card mt-4'>
								<div className='card-body' style={{ maxHeight: '44vh' }}>
									<h4 className='text-center color-grey fw-lighter text-uppercase'>
										Anuncios
									</h4>
									<div className='announces'>
										{anuncios
											.slice(0)
											.reverse()
											.map((anuncio, index) => (
												<div
													className='info p-2 mb-2'
													key={index}
												>
													<label>{anuncio.title}</label>
													<div className='text-muted small mb-1 d-flex align-items-center'>
														<div className='date d-flex align-items-center'>
															<CalendarMonthIcon
																style={{
																	fontSize: '1rem'
																}}
															/>
															{new Date(
																anuncio.date
															).toLocaleDateString() +
																' ' +
																new Date(
																	anuncio.date
																).toLocaleTimeString()}
														</div>
													</div>
													<div>{anuncio.description}</div>
												</div>
											))}
									</div>
								</div>
							</div>
						</div>
						<div className='col-xl-4 col-lg-6 col-md-12 mb-4 order-3 order-sm-3 order-md-3'>
							<div className='card'>
								<div className='card-body'>
									<h4 className='text-center color-grey fw-lighter text-uppercase'>
										Información Personal
									</h4>
									<div className='row mt-3'>
										<div className='col-lg-6'>
											<div className='info p-2 h-100'>
												<label>Nombre</label>
												<div>{employee.nombre}</div>
											</div>
										</div>
										<div className='col-lg-6'>
											<div className='info p-2 h-100'>
												<label>Apellidos</label>
												<div>{employee.apellidos}</div>
											</div>
										</div>
										<div className='col-sm-6 mt-3'>
											<div className='info p-2 h-100'>
												<label>DNI</label>
												<div>{employee.dni}</div>
											</div>
										</div>
										<div className='col-sm-6 mt-3'>
											<div className='info p-2 h-100'>
												<label>Comunidad Autónoma</label>
												<div>{employee.ccaa}</div>
											</div>
										</div>
										<div className='col-sm-6 mt-3'>
											<div className='info p-2 h-100'>
												<label>Sede</label>
												<div>{employee.sede}</div>
											</div>
										</div>
										<div className='col-sm-6 mt-3'>
											<div className='info p-2 h-100'>
												<label>Código Postal</label>
												<div>{employee.cp}</div>
											</div>
										</div>
										<div className='col-sm-6 mt-3'>
											<div className='info p-2 h-100'>
												<label>Provincia</label>
												<div>{employee.provincia}</div>
											</div>
										</div>
										<div className='col-sm-6 mt-3'>
											<div className='info p-2 h-100'>
												<label>Localidad</label>
												<div>{employee.localidad}</div>
											</div>
										</div>
										<div className='col-sm-6 mt-3'>
											<div className='info p-2 h-100'>
												<label>Teléfono</label>
												<div>{employee.telefono}</div>
											</div>
										</div>
										<div className='col-sm-6 mt-3'>
											<div className='info p-2 h-100'>
												<label>Email</label>
												<div>{employee.email}</div>
											</div>
										</div>
										<div className='col-sm-6 mt-3'>
											<div className='info p-2 h-100'>
												<div className='d-flex gap-2'>
													<label>
														Vacaciones (año natural)
													</label>

													{/* <Tooltip
														title='Es posible que la información no se haya actualizado correctamente. Estamos trabajando para solucionarlo'
														arrow
													>
														<InfoIcon color='warning' />
													</Tooltip> */}
												</div>
												<div>
													{employee.vacaciones} días disfrutados
												</div>
											</div>
										</div>
										<div className='col-sm-6 mt-3'>
											<div className='info p-2 h-100'>
												<div className='d-flex gap-2'>
													<label>
														Días libres (año natural)
													</label>

													{/* <Tooltip
														title='Es posible que la información no se haya actualizado correctamente. Estamos trabajando para solucionarlo'
														arrow
													>
														<InfoIcon color='warning' />
													</Tooltip> */}
												</div>
												<div>
													{employee.libres} días solicitados
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>

							<div className='card mt-3'>
								<div className='card-body'>
									<h4 className='text-center color-grey fw-lighter text-uppercase'>
										Solicitudes
									</h4>

									<div className='p-2'>
										<div className='d-flex'>
											<Calendar
												id='range'
												dateFormat='dd/mm/yy'
												locale='es'
												value={dateHolidays}
												className='form-control requestHolidays'
												placeholder='Selecciona las fechas'
												onChange={(e) => setDateHolidays(e.value)}
												selectionMode='range'
											></Calendar>

											{requestType == 'otrosMotivos' && (
												<input
													type='text'
													className='form-control ms-4'
													placeholder='Motivo de la solicitud'
													onChange={(e) =>
														setRequestReason(e.target.value)
													}
												/>
											)}

											{requestType == 'vacaciones' &&
												new Date().getMonth() == 0 &&
												new Date().getDate() <= 8 && (
													<select
														className='form-select ms-4'
														value={yearRequest}
														onChange={(e) =>
															setYearRequest(e.target.value)
														}
													>
														<option
															value={new Date().getFullYear()}
														>
															{new Date().getFullYear()}
														</option>
														<option
															value={
																new Date().getFullYear() -
																1
															}
														>
															{new Date().getFullYear() - 1}
														</option>
													</select>
												)}

											{requestType == 'diasPropios' &&
												enFechaDiasPropiosLastYear() && (
													<select
														className='form-select ms-4'
														value={yearRequest}
														onChange={(e) =>
															setYearRequest(e.target.value)
														}
													>
														<option
															value={new Date().getFullYear()}
														>
															{new Date().getFullYear()}
														</option>
														<option
															value={
																new Date().getFullYear() -
																1
															}
														>
															{new Date().getFullYear() - 1}
														</option>
													</select>
												)}
										</div>

										<div className='mt-3 text-center'>
											<input
												type='checkbox'
												name='solicitud'
												id='vacaciones'
												onChange={(e) => {
													if (e.target.checked) {
														setRequestType('vacaciones');
													} else {
														setRequestType('');
														setYearRequest(
															new Date().getFullYear()
														);
													}
												}}
												disabled={
													requestType == 'diasPropios' ||
													requestType == 'otrosMotivos'
												}
											/>
											<label
												htmlFor='vacaciones'
												className='mx-2 me-3'
											>
												Vacaciones
											</label>

											<input
												type='checkbox'
												name='solicitud'
												id='diasPropios'
												onChange={(e) => {
													if (e.target.checked) {
														setRequestType('diasPropios');
													} else {
														setRequestType('');
														setYearRequest(
															new Date().getFullYear()
														);
													}
												}}
												disabled={
													requestType == 'vacaciones' ||
													requestType == 'otrosMotivos'
												}
											/>
											<label
												htmlFor='diasPropios'
												className='mx-2 me-3'
											>
												Días Propios
											</label>

											<input
												type='checkbox'
												name='solicitud'
												id='otrosMotivos'
												onChange={(e) =>
													e.target.checked
														? setRequestType('otrosMotivos')
														: setRequestType('')
												}
												disabled={
													requestType == 'vacaciones' ||
													requestType == 'diasPropios'
												}
											/>
											<label
												htmlFor='otrosMotivos'
												className='mx-2'
											>
												Otros
											</label>
										</div>

										<button
											className='btn btn-primary mt-3 w-100 d-flex align-items-center justify-content-center'
											disabled={buttonBlocked}
											onClick={() => sendRequest()}
										>
											Solicitar
										</button>
									</div>
								</div>
							</div>
						</div>
						<ChangePassword type={'firstLoggin'} />
					</div>
				</div>
			</div>

			<NotificationContainer />
		</>
	);
}
