import create from "zustand";

const useData = create(set => ({
    data: {
        dni: '',
        ccaa: '',
        sede: ''
    },
    setData: (data) => set(state => ({ data: data }))
}))
  
export default useData;