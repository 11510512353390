import React, { useState, useEffect } from "react";

const ContadorDescanso = ({descanso}) => {
    const [time, setTime] = useState(0);

    useEffect(() => {
        let time2 = 0;

        if (descanso && descanso !== true) {
            let date = new Date();
            let hour = date.getHours();
            let minutes = date.getMinutes();
            let seconds = date.getSeconds();
            time2 = hour * 3600 + minutes * 60 + seconds;

            let date_entrada = new Date(descanso)
            let hour_entrada = date_entrada.getHours();
            let minutes_entrada = date_entrada.getMinutes();
            let seconds_entrada = date_entrada.getSeconds();
            let time_entrada = hour_entrada * 3600 + minutes_entrada * 60 + seconds_entrada;

            time2 = time2 - time_entrada;
        }

        if(descanso) {
            var interval = setInterval(() => {
                time2++;
                var seconds = time2 % 60;
                var minutes = Math.floor((time2 / 60) % 60);
                var hours = Math.floor(time2 / 3600);
                
                // Pon un 0 antes de los segundos si es menor que 10
                seconds = seconds < 10 ? '0' + seconds : seconds;
                // Pon un 0 antes de los minutos si es menor que 10
                minutes = minutes < 10 ? '0' + minutes : minutes;
                // Pon un 0 antes de las horas si es menor que 10
                hours = hours < 10 ? '0' + hours : hours;
                
                setTime(hours + ":" + minutes + ":" + seconds);
              }, 1000);
              
              return () => clearInterval(interval);
        }
    }, [descanso])

    return(
        time != 0 ? time : '00:00:00'
    )
}
export default ContadorDescanso;