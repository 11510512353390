import React, { useState, useEffect } from "react";

const Contador = ({fichaje, entrada}) => {
    const [time, setTime] = useState(0);
    let tempHours = 0;

    useEffect(() => {
        let time2 = 0;

        if (entrada && entrada !== '') {
            let date = new Date();
            let hour = date.getHours();
            let minutes = date.getMinutes();
            let seconds = date.getSeconds();
            time2 = hour * 3600 + minutes * 60 + seconds;

            let date_entrada = new Date(entrada)
            let hour_entrada = date_entrada.getHours();
            let minutes_entrada = date_entrada.getMinutes();
            let seconds_entrada = date_entrada.getSeconds();
            let time_entrada = hour_entrada * 3600 + minutes_entrada * 60 + seconds_entrada;

            time2 = time2 - time_entrada;
        }
        
        if(fichaje) {
            var interval = setInterval(() => {
                time2++;
                var seconds = time2 % 60;
                var minutes = Math.floor(time2 / 60);
                var hours = Math.floor(minutes / 60);
                minutes = minutes % 60; // asegurarse de que los minutos no superen 59
                //put 0 before seconds if seconds is less than 10
                seconds = seconds < 10 ? '0' + seconds : seconds;
                //put 0 before minutes if minutes is less than 10
                minutes = minutes < 10 ? '0' + minutes : minutes;
                //put 0 before hours if hours is less than 10
                hours = hours < 10 ? '0' + hours : hours;
                tempHours = hours;
                setTime(hours + ":" + minutes + ":" + seconds);
            }, 1000);
            return () => clearInterval(interval);                     
        }
    }, [fichaje])

    return(
        time != 0 ? tempHours < 8 ? time : '+8H' : '00:00:00'
    )
}
export default Contador;