import React, { useEffect, useState } from 'react';

import { Calendar, momentLocalizer } from 'react-big-calendar';

import moment from 'moment';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import 'moment/locale/es'; // added import for Spanish locale
import useData from '../../common/useData';
import { Modal } from '@mantine/core';
import { NotificationManager, NotificationContainer } from 'react-notifications';

const icons = {
	today: 'Hoy',
	previous: 'Anterior',
	next: 'Siguiente',
	month: 'Mes',
	week: 'Semana',
	day: 'Día',
	agenda: 'Agenda',
	date: 'Fecha',
	time: 'Hora',
	event: 'Evento'
};

export const Calendario = () => {
	const { data } = useData();
	const [list, setList] = useState([]);

	const [showCancelEvent, setShowCancelEvent] = useState(false);
	const [eventToCancel, setEventToCancel] = useState(null);

	const [refresh, setRefresh] = useState(false);

	useEffect(() => {
		fetch('https://server.fichajes.crossmedia360.com/getUserEventos', {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json'
			},
			body: JSON.stringify({
				dni: data.dni,
				sede: data.sede
			})
		})
			.then((res) => res.json())
			.then((data) => {
				let list = [];

				data.eventos?.map((event) => {
					console.log(event);
					list.push({
						id: event.id,
						title: event.title
							? 'Festivo: ' + event.title
							: event.type == 2
							? 'Jornada Reducida'
							: 'Evento',
						start: moment(event.fechaInicio).toDate(),
						end: moment(event.fechaFin).toDate(),
						color:
							event.type == 1 ? 'red' : event.type == 2 ? 'green' : 'blue'
					});
				});

				data.vacaciones?.map((event) => {
					list.push({
						id: !event.aproved ? 100000000 + event.id : 100000 + event.id,
						title:
							(!event.aproved ? 'PENDIENTE: ' : '') +
							(event.requestType == 'vacaciones'
								? 'Vacaciones'
								: event.requestType == 'diasPropios'
								? 'Asuntos Propios'
								: event.motivoOtros),
						start: moment(event.startDate).toDate(),
						end: moment(event.endDate).toDate(),
						color: !event.aproved
							? '#FF8F8F'
							: event.requestType == 'vacaciones'
							? 'lightblue'
							: 'orange'
					});
				});

				setList(list);
			})
			.catch((res) => {
				console.log('error');
			});
	}, [refresh]);

	return (
		<>
			<Calendar
				events={list}
				defaultDate={moment().toDate()}
				localizer={momentLocalizer(moment)}
				className='p-4'
				style={{ height: 'calc(100% - 56px)' }}
				culture={'es-ES'}
				eventPropGetter={(event) => {
					const eventData = list.find((ot) => ot.id === event.id);
					const backgroundColor = eventData && eventData.color;
					return { style: { backgroundColor } };
				}}
				onSelectEvent={(event) => {
					if (event.id < 100000000 || event.color != '#FF8F8F') return;
					setEventToCancel(event);
					setShowCancelEvent(true);
				}}
				messages={icons}
			/>

			<Modal
				opened={showCancelEvent}
				closeOnClickOutside={false}
				onClose={() => setShowCancelEvent(false)}
				title='Cancelar Evento'
				centered
			>
				¿Quieres cancelar{' '}
				{eventToCancel?.title === 'PENDIENTE: Vacaciones'
					? 'las vacaciones'
					: eventToCancel?.title === 'PENDIENTE: Asuntos Propios'
					? 'la solicitud de dias propios'
					: 'la solicitud '}{' '}
				del {moment(eventToCancel?.start).format('DD/MM/YYYY')} al{' '}
				{moment(eventToCancel?.end).format('DD/MM/YYYY')}?
				<button
					className='btn btn-danger w-100 mt-3'
					onClick={() => {
						fetch('https://server.fichajes.crossmedia360.com/cancelEvent', {
							method: 'POST',
							headers: {
								'Content-Type': 'application/json'
							},
							body: JSON.stringify({
								dni: data.dni,
								id: eventToCancel.id - 100000000
							})
						})
							.then((res) => res.json())
							.then((data) => {
								if (!data.deleted) {
									NotificationManager.error(
										'Error al borrar el evento.'
									);
									return;
								}

								setRefresh(!refresh);
								NotificationManager.success(
									'Evento borrado correctamente.'
								);
								setShowCancelEvent(false);
							})
							.catch((res) => {
								NotificationManager.error('Error al cancelar el evento.');
							});
					}}
				>
					Cancelar
				</button>
			</Modal>

			<NotificationContainer />
		</>
	);
};
